* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.corpo {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  position: relative;
  background-color: #0d2b1d;
  /* Cor sólida de fundo base */
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(8px);
  /* Aplica o efeito de desfoque */
  opacity: 0.5;
  /* Define opacidade para suavizar a sobreposição */
  z-index: 1;
  background-repeat: no-repeat;
  transition: opacity 0.5s ease-in-out;
  /* Transição suave de opacidade */
}

.background.fade {
  opacity: 0;
  /* Começa invisível durante o fade */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
    /* Alcança a opacidade final do fundo */
  }
}

.scontainer {
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: #ffffff91;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
  transition: transform 0.6s;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 1))
}

.avatar.spin {
  animation: spin 0.6s linear;
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link {
  font-size: 18px;
  color: white;
  background-color: #13361c;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.link:hover {
  background-color: #2f6d32;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.links:hover>:not(:hover) {
  filter: blur(2px);
  opacity: 0.8;
}